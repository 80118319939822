<template>
	<article class="view-home">
		<div class="site-wrapper rythm-v">
			<header class="site-wrapper">
				<h1>Étude ADARIC</h1>
			</header>

			<div class="grid-ram">
				<div class="card shadow rythm-v"
						 v-if="!this.userIsLoggedIn">
					<p><em>Étude observationnelle multicentrique longitudinale, de type « observatoire ».</em></p>
					<p>
						Observer en vie réelle les effets, en termes d’efficacité, du système Adacolumn® à 6 mois de son instauration chez des patients atteints de RIC (polyarthrite rhumatoïde, spondyloarthrite, rhumatisme psoriasique) après échec, contre-indication ou intolérance des traitements conventionnels et/ou ciblés.
					</p>
				</div>

				<div class="card shadow"
						 v-if="this.userIsLoggedIn">
					<h2>Documents de l’étude</h2>
					<ul role="list"
							class="list--files-download">
						<li>
							<a target="_blank"
								 :href='`${documentsUrl}/23JAC-ADARIC_protocole_v2-1_20250114_YSR.pdf`'>Protocole ADARIC</a>
						</li>
						<li>
							<a target="_blank"
								 :href='`${documentsUrl}/ECRF-ADARIC-VERSION-01.2025.pdf`'>eCRF ADARIC</a>
						</li>
						<li>
							<a target="_blank"
								 :href='`${documentsUrl}/23JAC-ADARIC_CPP-MS1_20250120.pdf`'>Comité de protection des personnes – Ile de France VIII</a>
						</li>
						<li>
							<a target="_blank"
								 :href='`${documentsUrl}/23JAC-ADARIC_NINO-prospective_v1-1_20240913_YSR.pdf`'>Note d’information pour les inclusions en prospectif</a>
						</li>
						<li>
							<a target="_blank"
								 :href='`${documentsUrl}/23JAC-ADARIC_NINO-retrospective_v1-1_20240913_YSR.pdf`'>Note d’information pour les inclusions en rétrospectif</a>
						</li>
					</ul>
				</div>

				<div class="card shadow"
						 v-if="!maintenanceMode">
					<loginForm v-if="!this.userIsLoggedIn" />
					<div class="rythm-v"
							 v-else>
						<h2>Vous êtes connecté</h2>
						<p>Bonjour {{ user.firstname }} {{ user.lastname }}.</p>
						<nav class="navigation">
							<ul>
								<li>
									Accéder à la
									<router-link to="/cases">liste des patients</router-link>
								</li>
								<li>
									<button class="button--link"
													@click.prevent="logoutUser">
										Se déconnecter
									</button>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				<div class="card shadow"
						 v-else>
					<p><strong>La plateforme de l'eCRF est actuellement en maintenance.</strong></p>
					<p>Merci de votre patience et à très bientôt !</p>
				</div>

			</div>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/config";
@import "~@/scss/06-components/components.card";
</style>

<script>
import { appConfig } from "../config.js";
import loginForm from "@/components/loginForm.vue";

export default {
	name: "home",

	components: {
		loginForm,
	},

	data: function () {
		return {
			maintenanceMode: true,
		};
	},

	computed: {
		documentsUrl() {
			return `${appConfig.API_BASE_URL}/documents`;
		}
	},

	methods: {},

	mounted: function () { },
};
</script>
